$maxh: 100vh;

/*body {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -10;
  height: 100%;
  color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./Images/AdobeStock_114048878.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-color: #464646;
}*/

* {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  //max-height: calc(100% + 56px);
  z-index: -10;

  /*background: url("./Images/AdobeStock_114048878.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: black;
}
p,
h1,
h2,
div {
  color: black;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

//only on business
.smallInput input,
.smallInput textarea,
.smallInput .ant-select,
.smallInput button {
  font-size: 11px !important;
}

.ant-input {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.smallInput {
  font-size: 11px !important;
}
.ant-input-suffix {
  right: 12px !important;
}
.smallInput .ant-input-suffix {
  font-size: 11px !important;
  right: 8px !important;
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
  top: 50% !important;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 22px !important;
}
.smallInput .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 15px !important;
}
.ant-btn-sm {
  font-size: 11px !important;
}
//wenn nur sm verwendet wird:

.ant-select-dropdown {
  font-size: 11px !important;
}
.ant-select-dropdown-menu-item {
  line-height: 14px !important;
}

.ant-select-dropdown-menu-item-group-title {
  line-height: 20px !important;
  height: 20px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 8px !important;
}
.ant-select-dropdown-menu-item {
  line-height: 14px !important;
}

//wenn nur sm verwendet wird ende
//only on business ende

@media only screen and (max-width: 768px) {
  input,
  textarea {
    font-size: 16px !important;
  }
}

.readonly {
  font-size: 10px !important;
}

@media only screen and (max-width: 620px) {
  .cookiepadding {
    padding-top: 10px;
  }
}

.ant-input-clear-icon {
  font-size: 14px !important;
}
.slide {
  display: flex !important;

  justify-content: center;
  background: rgba(0, 0, 0, 0.6) !important;
}

.ant-select {
  width: 100%;
}
textarea {
  resize: none !important;
}
.ant-select-dropdown {
  max-height: 150px;
}
.ant-select-dropdown {
  div,
  ul {
    max-height: 150px;
  }
}

.ant-select-dropdown li {
  padding: 3px;
}

.ant-select-dropdown-menu-item-active {
  background-color: lighten(#1890ff, 30) !important;
}
.ant-select-dropdown ul::-webkit-scrollbar {
  width: 0px !important;
}
.ant-select-dropdown ul {
  scrollbar-width: none;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(lighten(#1890ff, 20)), to(darken(#1890ff, 20)), color-stop(0.6, #1890ff));
}
.OTableHolder::-webkit-scrollbar {
  width: 0px !important;
}
.OTableHolder {
  scrollbar-width: none;
}

.ant-slider-rail {
  background-color: lightgrey !important;
}

.ant-slider {
  margin-top: 11px !important;
  margin-left: 0.5em;
  width: calc(100% - 1em) !important;
}
.ant-slider-with-marks {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.smallInput .ant-slider-mark {
  font-size: 11px;
}
.smallInput .ant-slider {
  margin-top: 7px !important;
}
.smallInput .ant-slider-with-marks {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.ant-select-dropdown-menu-item-group-title {
  color: black !important;
  font-weight: bold;
}

.ant-upload-btn {
  padding: 0 !important;
}
.ant-upload-drag-icon {
  margin-bottom: 0 !important;
}
.OurRow * {
  //flex-shrink: 0;
}
