$lightningColor: #1890ff;

.dateboxhighlight:hover {
  background-color: lighten($lightningColor, 30);
}
.dateboxpicked {
  background-color: $lightningColor;
  color: white !important;
}

.ODatePickerContainer-small {
  .ODatePickerContainerItem {
    margin: 2px;
    width: 20px;
    height: 20px;
  }
  width: 170px;
  font-size: 11px;
}

.ODatePickerContainer-medium {
  .ODatePickerContainerItem {
    margin: 2px;
    width: 24px;
    height: 24px;
  }
  width: 198px;
  font-size: 13px;
}

.ant-popover-inner-content {
  padding: 6px !important;
}
