$primary: #1890ff;
$success: darken(#87d068, 10%);
$danger: #ff5500;
$warning: #faad14;
$disabled: lightgrey;
$white: white;
//grey:;
.OButton {
  border-radius: 3px;
  border: 1px solid;
  outline: none !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  transition: background-color 0.3s;
  cursor: pointer;
  margin: 6px;
}

.Cprimary {
  background-color: $primary;
  border-color: $primary;
  color: white;

  &:hover {
    background-color: lighten($primary, 12%) !important;
    border-color: lighten($primary, 12%) !important;
  }
  &:focus {
    background-color: lighten($primary, 12%) !important;
    border-color: lighten($primary, 12%) !important;
  }
  &:active {
    background-color: darken($primary, 12%) !important;
    border-color: darken($primary, 12%) !important;
  }
}

.Csuccess {
  background-color: $success;
  border-color: $success;
  color: white;

  &:hover {
    background-color: lighten($success, 10%) !important;
    border-color: lighten($success, 10%) !important;
  }
  &:focus {
    background-color: lighten($success, 10%) !important;
    border-color: lighten($success, 10%) !important;
  }
  &:active {
    background-color: darken($success, 10%) !important;
    border-color: darken($success, 10%) !important;
  }
}
.Cdanger {
  background-color: $danger;
  border-color: $danger;
  color: white;

  &:hover {
    background-color: lighten($danger, 12%) !important;
    border-color: lighten($danger, 12%) !important;
  }
  &:focus {
    background-color: lighten($danger, 12%) !important;
    border-color: lighten($danger, 12%) !important;
  }
  &:active {
    background-color: darken($danger, 12%) !important;
    border-color: darken($danger, 12%) !important;
  }
}
.Cwarning {
  background-color: $warning;
  border-color: $warning;
  color: white;

  &:hover {
    background-color: lighten($warning, 12%) !important;
    border-color: lighten($warning, 12%) !important;
  }
  &:focus {
    background-color: lighten($warning, 12%) !important;
    border-color: lighten($warning, 12%) !important;
  }
  &:active {
    background-color: darken($warning, 12%) !important;
    border-color: darken($warning, 12%) !important;
  }
}
.Cwhite {
  background-color: $white;
  border-color: lightgrey;
  color: black;

  &:hover {
    border-color: $primary !important;
  }
  &:focus {
    border-color: $primary !important;
  }
  &:active {
    background-color: darken($white, 12%) !important;
  }
}
.OBtnDisabled {
  background-color: $disabled !important;
  border-color: $disabled !important;

  &:hover {
    background-color: $disabled !important;
    border-color: $disabled !important;
    cursor: not-allowed !important;
  }
}
