.InputCombo:hover {
  .ant-input,
  .ComboInputVerbinder,
  .ant-select-selection {
    border-color: #1890ff !important;
  }
}
.InputCombo:focus-within {
  .ant-input,
  .ComboInputVerbinder,
  .ant-select-selection {
    border-color: #1890ff !important;
  }
}
.ComboInputVerbinder {
  border-radius: 3px;
  height: 32px;
}
.smallInput {
  .ComboInputVerbinder {
    height: 24px !important;
  }
}

.InputCombo {
  .ant-input,
  .ant-select-selection,
  .ComboInputVerbinder {
    box-shadow: none !important;
    transition: all 0.3s !important;
  }
}

.InputCombo .InputHOC:not(:first-child):not(:last-child) {
  .ant-input,
  .ant-select-selection,
  .ant-select-selection__rendered,
  .ComboInputVerbinder {
    padding-right: 0 !important;
    border-right: none !important;
    margin-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.InputCombo .ComboInputVerbinder:not(:first-child):not(:last-child) {
  padding-right: 0 !important;
  border-right: none !important;
  margin-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.InputCombo .ComboInputVerbinder:first-child {
  padding-right: 0 !important;
  border-right: none !important;
  margin-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.InputCombo .ComboInputVerbinder:last-child {
  padding-left: 0 !important;
  margin-left: 0 !important;
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.InputCombo .InputHOC:first-child {
  .ant-input,
  .ant-select-selection,
  .ant-select-selection__rendered,
  .ComboInputVerbinder {
    padding-right: 0 !important;
    margin-right: 0 !important;
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.InputCombo .InputHOC:last-child {
  .ant-input,
  .ant-select-selection,
  .ant-select-selection__rendered,
  .ComboInputVerbinder {
    padding-left: 0 !important;
    margin-left: 0 !important;
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.ComboInputVerbinder * {
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
}
