$mycolor: #1890ff;

.NavBarHolder .ant-menu-submenu-title:hover,
.NavBarHolder .ant-menu-submenu-item:hover,
.NavBarHolder .ant-menu-item:hover,
.ant-menu-sub .ant-menu-item:hover {
  background-color: darken(#1890ff, 20%) !important;
}
.NavBarHolder .ant-menu-item-selected,
.NavBarHolder .ant-menu-submenu-selected,
.NavBarHolder .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-sub .ant-menu-item-selected {
  background-color: lighten($mycolor, 20%) !important;
}

.NavBarHolder .ant-menu-item,
.NavBarHolder .ant-menu-submenu-title,
.ant-menu-sub .ant-menu-item {
  border-bottom: 1px solid white;
  margin: 0 !important;
  color: white !important;
  background-color: $mycolor;
  border-right: 5px solid #006ed5 !important;
}

.NavBarHolder .ant-menu-inline-collapsed .ant-menu-item,
.NavBarHolder .ant-menu-inline-collapsed .ant-menu-submenu-title {
  border-right: none !important;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow {
  visibility: hidden;
}

.NavBarHolder {
  position: fixed;
  z-index: 100;
}
.NavBarHolder .ant-menu li:first-child {
  border-top: 1px solid white !important;
}

.NavBarHolder .ant-menu-inline-collapsed {
  width: 50px !important;
}

.NavBarHolder ~ * {
  margin-left: 50px;
}

.NavBarHolder ~ .OurHeader {
  width: calc(100% - 50px) !important;
}
