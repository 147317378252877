.ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: black !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: black !important;
}
.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 0 !important;
}
.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: grey !important;
}
.ant-steps-item-title::after {
  background: grey !important;
}
.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1890ff !important;
}

.ant-steps-item-wait > .ant-steps-item-tail::after {
  background-color: grey !important;
}
.ant-steps-item-tail::after {
  background: grey !important;
}

.ant-steps-item-finish > .ant-steps-item-tail::after {
  background-color: #1890ff !important;
}

.ant-select-selection__clear {
  opacity: 1 !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.9) !important;
}
form.submitted :required:invalid {
  border-color: #f5222d !important;
}

/*.touched:required:invalid {
  border-color: #f5222d!important;
}*/

:invalid {
  box-shadow: none !important;
}

.ant-input,
.ant-select-selection {
  border: none !important;
  box-shadow: none !important;
}

.inputBorderWraper:focus-within {
  border-color: #1890ff !important;
}

form.submitted .invalid .inputBorderWraper {
  border-color: #f5222d !important;
}
.ant-select-selection__rendered {
  line-height: 32px !important;
}

.ant-select-sm .ant-select-selection__rendered {
  line-height: 24px !important;
}
/*
form .ant-input:invalid:focus {

    border-color: #f5222d !important;
    box-shadow: #f5222d 0px 0px 5px 1px !important;

}
form .ant-input:valid:focus{
    border-color: green!important;
    box-shadow: green 0px 0px 5px 1px!important;
  }
*/

.dark {
  background-color: black !important;
}
