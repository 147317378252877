.OContent {
  min-height: calc(100vh - 53px);
}

.OurHeader ~ .OContent {
  min-height: calc(100vh - 108px);
}

.OurFooter {
  color: white;
}
/*
.OurHeader ~ .OurFooter{

  margin-top:20px;
}*/
