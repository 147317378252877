.OTable {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border: none;
}

.OTable-small {
  font-size: 11px;
}
.OTable-medium {
  font-size: 13px;
}
.OTable-large {
  font-size: 15px;
}

.OTableHeaderItem,
.OTableItem {
  padding: 3px;
  word-break: break-word;
  word-wrap: normal;
  width: 150px;
}

.OTable-small .OTableItem {
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}
.OTableHeaderItem {
  background-color: #34495c;
  color: whitesmoke !important;
  font-weight: normal;
  font-size: 13px !important;
  padding-left: 4px !important;
  padding-right: 4px;
}

.OTable-bordered {
  .OTableItem,
  .OTableHeaderItem,
  .OTableSearchbarItem {
    border-left: 1px solid darkgrey;
    border-right: 1px solid darkgrey;
    box-sizing: border-box !important;
  }
  .OTableItem:nth-child(1) {
    border-left: none;
  }
  .OTableHeaderItem:nth-child(1) {
    border-left: none;
  }
  .OTableSearchbarItem:nth-child(1) {
    border-left: none;
  }
  .OTableItem:last-child {
    border-right: none;
  }
  .OTableHeaderItem:last-child {
    border-right: none;
  }
  .OTableSearchbarItem:last-child {
    border-right: none;
  }
}
.OExtenseTable {
  .OTableItem:nth-child(2) {
    border-left: none;
  }
  .OTableHeaderItem:nth-child(2) {
    border-left: none;
  }
  .OTableSearchbarItem:nth-child(2) {
    border-left: none;
  }
}

.OTable-mouseOver {
  .OTableRow:hover .OTableItem {
    background-color: #e1edfa !important;
  }
}

.OTable-striped .OTableRow:nth-child(odd) {
  background-color: #c3dffa;
}
